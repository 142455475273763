// src/UserContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreditsProvider } from './CreditsContext';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
//   const navigate = useNavigate();

  useEffect(() => {

    const checkAuth = async () => {
      const token = localStorage.getItem('token');
      // console.log(token)
      if (!token) {
        setUser(null);
        setLoading(false);
        return;
      }
    
      try {
        const response = await fetch('http://127.0.0.1:5000/api/check-auth', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
    
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        // console.log('User data:', data);
        if (data.user) {
          setUser(data.user);
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
        setUser(null);
      } finally {
        setLoading(false);
      }
    };

    // const checkAuth = async () => {
    //   const token = localStorage.getItem('token');
    //   try {
    //     const response = await fetch('http://127.0.0.1:5000/api/check-auth', {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     });
    
    //     if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        
    //     const data = await response.json();
    //     console.log('User data:', data);
    //     if (data.user) {
    //       setUser(data.user);
    //     } else {
    //       setUser(null);
    //     }
    //   } catch (error) {
    //     console.error('Error checking authentication:', error);
    //     setUser(null);
    //   } finally {
    //     setLoading(false);
    //   }
    // };
    
    // const checkAuth = async () => {
    //   try {
    //     const response = await fetch('http://127.0.0.1:5000/api/check-auth', {
    //       // headers: {
    //       //   Authorization: `Bearer ${localStorage.getItem('token')}`,
    //       // },
    //       // credentials: 'include', // Uncomment if needed
    //     });
    
    //     if (!response.ok) {
    //       throw new Error(`HTTP error! status: ${response.status}`);
    //     }
    
    //     const data = await response.json();
    //     if (data.user) {
    //       setUser(data.user);
    //     } else {
    //       setUser(null); // Handle if no user data returned
    //     }
    //   } catch (error) {
    //     console.error('Error checking authentication:', error);
    //     setUser(null); // Clear user on error
    //   } finally {
    //     setLoading(false); // Update loading state
    //   }
    // };
    checkAuth();
  }, []);

  const login = async (userDetails) => {
    try {

      console.log(userDetails, "Set log in variable")
      // Store the JWT token in localStorage
      localStorage.setItem('token', userDetails.access_token);

  
      // Set the user state with the provided user details
      if(userDetails.username){
        setUser({
        email: userDetails.email,
        username: userDetails.username,
        credits: userDetails.credits,
      });
      }

      else if(!userDetails.username){
        setUser({
        email: userDetails.email,
        credits: userDetails.credits,
      });
      }
  
      // Redirect to the saved path or default to the dashboard
      // const redirectPath = localStorage.getItem('redirectPath') || '/';
      // localStorage.removeItem('redirectPath');
      // navigate(redirectPath);
    } catch (error) {
      console.error('Error logging in:', error);
    }
  };

  const logout = () => setUser(null);

  return (
    <UserContext.Provider value={{ user, login, logout, loading }}>
        {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);

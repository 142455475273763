import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';

const socket = io('http://127.0.0.1:5000');  // Ensure this URL is correct

function QueueSocket() {
  const [queueLength, setQueueLength] = useState(0);

  useEffect(() => {
    socket.on('queue_length_update', (data) => {
      console.log('Received queue length update:', data);
      setQueueLength(data.queue_length);
    });

    return () => {
      socket.off('queue_length_update');
    };
  }, []);

  return (
    <div>
      <h2 className="text-white">Queue Length: {queueLength}</h2>
    </div>
  );
}

export default QueueSocket;

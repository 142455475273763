import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route, Navigate, useNavigate, redirect } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import { ChakraProvider } from '@chakra-ui/react';
import axios from 'axios';
import App from './App';
import Login from './pages/Login';
import Change from './pages/Change';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import { CreditsProvider } from './context/CreditsContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useUser } from './context/UserContext';
import { UserProvider } from './context/UserContext';
import AuthAndLogin from './components/AuthAndLogin';
import Upgrade from './components/Upgrade'

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, CustomCheckoutProvider } from '@stripe/stripe-js';
import PaymentPage from './pages/PaymentPage';
import PaymentApp from './pages/PaymentApp'
import Payment from './pages/Payment'
import Completion from './pages/Completion'
import Subscription from './pages/Subscription'
import MetadataHistory from './components/MetadataHistory';
import GenerationHistory from './components/GenerationHistory';
import Contact from './components/Contact';
import { ThemeProvider } from './context/ThemeContext';
import Newsletter from './components/Newsletter';

const stripePromise = loadStripe('pk_test_51OuL7ILELLC72Qb1mY2uG47vCvI8YWQ5DF9eaFMcfGcKR058LNjRDQLghbipqTAqUM2YGwuFjWN2M8N4XADk9YLB00pHjiJ2rC');


const AppRoutes = () => {
  const { user, loading, logout } = useUser(); // Use the UserContext
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  // const [ stripePromise, setStripePromise ] = useState(null);


  let navigate = useNavigate();

   const appearance = {
    theme: 'stripe',
  };
  const options = {
    appearance,
  };


  if (loading) {
    return <div>Loading...</div>; // Show loading indicator while checking authentication
  }

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    navigate("/login");
  };

  return (
    <Routes>
      {/* <Route exact path="/" element={<App />} /> */}
      <Route exact path="/" element={<Newsletter />} />
      {/* <Route exact path="/contact" element={<Contact />} />
      <Route path="/login" element={<AuthAndLogin />} />
      <Route exact path="/register" element={<Register />} />
      <Route exact path="/pricing" element={<Upgrade stripePromise={stripePromise}/>} />
      <Route exact path="/payment:clientSecret" element={<Payment stripePromise={stripePromise}/>}/>
      <Route exact path="/completion/:clientSecret" element={<Completion stripePromise={stripePromise}/>} />
      <Route exact path="/metadata" element={<Change stripePromise={stripePromise} />} />
      <Route exact path="/history" 
      element={
      <ProtectedRoute>
        <GenerationHistory stripePromise={stripePromise} />
        </ProtectedRoute>} />

      <Route exact path="/dashboard" element={<ProtectedRoute><Dashboard onLogout={handleLogout} /></ProtectedRoute>}/> */}
    </Routes>
  );
};

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="169106080677-1dusf2gnae3alge7m37hr3o8na2e4gjj.apps.googleusercontent.com">
    <ChakraProvider>
      <UserProvider>
      <Elements stripe={stripePromise}>
        <CreditsProvider>
          <ThemeProvider>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
          </ThemeProvider>
        </CreditsProvider>
        </Elements>
      </UserProvider>
    </ChakraProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

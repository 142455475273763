import React, { createContext, useState, useEffect, useContext } from 'react';

// Create a Context for the credits
const ThemeContext = createContext();

// Custom hook for using the CreditsContext
export const useTheme = () => useContext(ThemeContext);

// Context Provider component
export const ThemeProvider = ({ children }) => {
  const [dark, setDark] = useState(true);

  useEffect(()=>{
    document.body.classList.toggle("dark");
  },[dark])
  // console.log(credits, "CREDITS")

  return (
    <ThemeContext.Provider value={{ dark, setDark }}>
      {children}
    </ThemeContext.Provider>
  );
};

// src/components/QueueLength.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const QueueLength = () => {
    const [queueLength, setQueueLength] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchQueueLength = async () => {
            try {
                fetch('http://127.0.0.1:5000/api/queue_length', {
                method: 'GET',
                })
                .then(response => response.json())
                .then(({ queue_length }) => {
                    setQueueLength(queue_length);
                })
                // setQueueLength(response.data.queue_length);
            } catch (err) {
                setError('Failed to fetch queue length');
            } finally {
                setLoading(false);
            }
        };

        fetchQueueLength();
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div className="w-fit">
            <p className="dark:text-white text-white flex">Queue: {queueLength}</p>
        </div>
    );
};

export default QueueLength;

import React from 'react'
import {CustomCheckoutProvider} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm'
import { Elements } from '@stripe/react-stripe-js';
import { useLocation, useParams, useNavigate } from 'react-router-dom';

const stripePromise = loadStripe("pk_test_51OuL7ILELLC72Qb1mY2uG47vCvI8YWQ5DF9eaFMcfGcKR058LNjRDQLghbipqTAqUM2YGwuFjWN2M8N4XADk9YLB00pHjiJ2rC", {
  betas: ['custom_checkout_beta_3'],
});

const PaymentApp = () => {
const {clientSecret} = useParams()
const options = {
    clientSecret,
    appearance: { /* Your custom appearance options here */ }
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <CustomCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
        <CheckoutForm />
      </CustomCheckoutProvider>
    </Elements>
  )
}

export default PaymentApp

import React, {useEffect, useState} from 'react'
import '../App.css'
import {useUser} from '../context/UserContext'
import {useCredits} from '../context/CreditsContext'
import { useTheme } from '../context/ThemeContext'


const Navbar = () => {

    const [data, setData] = useState();
    const [sender, setSender] = useState();
    const [senderId, setSenderId] = useState();
    const [conversations, setConversations] = useState([]);
    const [usernames, setUsernames] = useState([]);
   //  const [dark, setDark] = useState(true);
   const { dark, setDark } = useTheme()

    const {user} = useUser()
    const {credits} = useCredits()

    const darkModeHandler = () => {
        setDark(!dark);
      //   document.body.classList.toggle("dark");
    }

  return (
    <header class="bg-background sticky top-0 z-50">
   <nav class="mx-auto flex items-center justify-between p-3 md:p-6 lg:px-8" aria-label="Global">
      <div class="mr-4 flex shrink-0">
         <a hreflang="en" class="block hover:no-underline active:no-underline" href="/">
            <div class="flex items-center justify-center content-center">
               {/* <img alt="Metadata Changer for Onlyfans" loading="lazy" width="40" height="40" decoding="async" data-nimg="1" class="h-10 w-auto" src="/images/logo.png"/> */}
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="" className="fill-white dark:fill-white flex items-center justify-center content-center w-6 h-6 bg-gradient-to-r from-purple-500 from-10% via-sky-500 via-30% to-emerald-500 to-90%">
                  <path fillRule="evenodd" d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z" clipRule="evenodd" />
                  </svg>

               <div class="lg:flex gap-x-1 items-center justify-center content-center text-xl text-primary dark:text-gray-100 hidden h-10 p-2 align-middle font-semibold">SOCIALMETA <span className="flex text-xs bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 from-10% via-sky-500 via-30% to-emerald-500 to-90%">AI</span></div>
            </div>
         </a>
      </div>
      <div class="flex space-x-2 lg:hidden">
         <div class="flex items-center">
            {/* <div class="hidden sm:block">
               <div class="flex items-center" type="button" id="radix-:R58muj5ta:" aria-haspopup="menu" aria-expanded="false" data-state="closed">
                  <button class="justify-center text-sm font-semibold transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-background focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 text-primary hover:bg-accent hover:text-accent-foreground h-9 rounded-full px-4 py-2 flex items-center space-x-2">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" class="lucide lucide-globe h-4 w-4">
                        <circle cx="12" cy="12" r="10"></circle>
                        <path d="M12 2a14.5 14.5 0 0 0 0 20 14.5 14.5 0 0 0 0-20"></path>
                        <path d="M2 12h20"></path>
                     </svg>
                     <span class="text-primary ">English</span>
                  </button>
               </div>
            </div> */}
            <div class="">
               <button onClick={()=> darkModeHandler()} className="flex items-center justify-center text-sm font-semibold transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-background focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 dark:text-purple-400 hover:bg-accent hover:text-accent-foreground h-9 w-9 rounded-sm" type="button" id="radix-:r0:">
                  {dark &&
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" class="dark:stroke-purple-400 text-purple-400 h-4 w-4">
                     <circle cx="12" cy="12" r="4"></circle>
                     <path d="M12 2v2"></path>
                     <path d="M12 20v2"></path>
                     <path d="m4.93 4.93 1.41 1.41"></path>
                     <path d="m17.66 17.66 1.41 1.41"></path>
                     <path d="M2 12h2"></path>
                     <path d="M20 12h2"></path>
                     <path d="m6.34 17.66-1.41 1.41"></path>
                     <path d="m19.07 4.93-1.41 1.41"></path>
                  </svg>
                  }
                  {!dark &&
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                     <path strokeLinecap="round" strokeLinejoin="round" d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z" />
                  </svg>
                  }
               </button>
            </div>
            {user && 
        <div className="dropdown dropdown-end ml-2 bg-blue-500 dark:bg-purple-500 rounded-[5px] my-3">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" tabIndex={0} className="m-1 w-7 h-7 hover:opacity-[80%] transition-all duration-300">
            <path fillRule="evenodd" d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" clipRule="evenodd" />
          </svg>
          <ul tabIndex={0} className="dropdown-content font-semibold z-[1] menu p-2 shadow dark:bg-gray-700 bg-white rounded-[7px] w-52 dark:text-white text-black hover:text-white/80">
            <li><a href="/pricing" className="hover:text-white/50 flex gap-x-2">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="" className="w-4 h-4 dark:stroke-white stroke-black bg-gray-100/10 rounded-full">
              <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
            </svg>{credits}</a></li>
            <li><a href="/history" className="hover:text-white/50">History</a></li>
            <li><a href="/metadata" className="hover:text-white/50 flex gap-x-2">
               Metadata Changer</a></li>
          </ul>
        </div>
        }
        {/* {!user && 
            <a href="/login" class="inline-flex items-center justify-center text-sm font-semibold transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-background focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 text-primary dark:text-purple-400 hover:bg-accent hover:text-accent-foreground h-9 rounded-full px-4 py-2">Sign In</a>
        } */}
         </div>
         <button type="button" class="-m-2.5 hidden md:inline-flex items-center justify-center rounded-md p-2.5">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="false" data-slot="icon" class="h-6 w-6">
               <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"></path>
            </svg>
         </button>
      </div>
      <div class="hidden gap-x-12 lg:flex lg:flex-1 lg:justify-center">
          {/* <a hreflang="en" class="text-sm font-semibold leading-6 text-primary dark:text-purple-300" href="/pricing">Pricing</a> */}
        </div>
      <div class="hidden lg:flex lg:items-center lg:justify-end lg:gap-x-3">
         {/* <div class="flex items-center" type="button" id="radix-:R1gmuj5ta:" aria-haspopup="menu" aria-expanded="false" data-state="closed">
            <button class="justify-center text-sm font-semibold transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-background focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 text-primary hover:bg-accent hover:text-accent-foreground h-9 rounded-full px-4 py-2 flex items-center space-x-2">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" class="lucide lucide-globe h-4 w-4 stroke-primary dark:stroke-purple-300">
                  <circle cx="12" cy="12" r="10"></circle>
                  <path d="M12 2a14.5 14.5 0 0 0 0 20 14.5 14.5 0 0 0 0-20"></path>
                  <path d="M2 12h20"></path>
               </svg>
               <span class="dark:text-purple-300 text-primary">English</span>
            </button>
         </div> */}
         <button onClick={()=> darkModeHandler()} className="flex items-center justify-center text-sm font-semibold transition-colors outline-none focus:border-none focus:outline-none focus:ring-offset-2 focus:ring-none focus:outline-none disabled:pointer-events-none disabled:opacity-50 text-primary dark:text-purple-400 hover:bg-purple-200 hover:text-accent-foreground h-9 w-9 rounded-sm" type="button" id="radix-:r0:">
                  {dark &&
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" class="lucide lucide-sun h-4 w-4">
                     <circle cx="12" cy="12" r="4"></circle>
                     <path d="M12 2v2"></path>
                     <path d="M12 20v2"></path>
                     <path d="m4.93 4.93 1.41 1.41"></path>
                     <path d="m17.66 17.66 1.41 1.41"></path>
                     <path d="M2 12h2"></path>
                     <path d="M20 12h2"></path>
                     <path d="m6.34 17.66-1.41 1.41"></path>
                     <path d="m19.07 4.93-1.41 1.41"></path>
                  </svg>
                  }
                  {!dark &&
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-primary dark:hover:stroke-primary dark:stroke-purple-300 dark:text-purple-300">
                     <path strokeLinecap="round" strokeLinejoin="round" d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z" />
                  </svg>
                  }
         </button>
         {/* {!user && (
         <a href="/login" className="hover:text-purple-600 hover:bg-purple-200 hover:text-accent-foreground inline-flex items-center justify-center text-sm font-semibold transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-background focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 text-primary dark:text-purple-300 hover:bg-accent hover:text-accent-foreground h-9 rounded-full px-4 py-2">Sign In</a>
         )} */}
         </div>
   </nav>
   <div className="fixed top-[1px] left-[1px] w-[1px] h-0 -m-[1px] overflow-hidden clip-[rect(0, 0, 0, 0)] white-space-nowrap"></div>
</header>
  )
}

export default Navbar

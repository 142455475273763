import React, { createContext, useState, useEffect, useContext } from 'react';

// Create a Context for the credits
const CreditsContext = createContext();

// Custom hook for using the CreditsContext
export const useCredits = () => useContext(CreditsContext);

// Context Provider component
export const CreditsProvider = ({ children }) => {
  const [credits, setCredits] = useState(0);
  

  // console.log(credits, "CREDITS")

  return (
    <CreditsContext.Provider value={{ credits, setCredits }}>
      {children}
    </CreditsContext.Provider>
  );
};
